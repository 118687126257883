import React from "react"
import { Grid, Typography, Container } from "@mui/material"

import { StaticImage } from "gatsby-plugin-image"

import { Center, FancyHr } from "../Layout"
import { AnimateOnScroll } from "../AnimateOnScroll"

import PartnersHero from "./PartnersHero"
import { Segment } from "../Segment"
import { Section } from "../Section"

const Partners = () => {
  return (
    <>
      <PartnersHero />
      <Segment>
        <AnimateOnScroll animateIn="fadeIn" animateOut="fadeIn">
          <Container>
            <Grid container>
              <Grid item xs={12}>
                <Typography variant="h4" color="primary" align="center">
                  Our Partners
                </Typography>
                <FancyHr></FancyHr>
              </Grid>
            </Grid>
          </Container>
          <Section>
            <Grid container>
              <Grid item xs={12} md={4}>
                <Center>
                  <StaticImage
                    src="../../images/partners/epsilon-lambda.jpg"
                    alt="elb logo"
                    placeholder="blurred"
                  />
                </Center>
              </Grid>
              <Grid item xs={12} md={4}>
                <Center>
                  <StaticImage
                    src="../../images/partners/fmd.jpg"
                    alt="elb logo"
                    placeholder="blurred"
                  />
                </Center>
              </Grid>
              <Grid item xs={12} md={4}>
                <Center>
                  <StaticImage
                    src="../../images/partners/pga.jpg"
                    alt="elb logo"
                    placeholder="blurred"
                  />
                </Center>
              </Grid>
              <Grid item xs={12} md={4}>
                <Center>
                  <StaticImage
                    src="../../images/partners/sigma-pi-phi-frat.jpg"
                    alt="elb logo"
                    placeholder="blurred"
                  />
                </Center>
              </Grid>
              <Grid item xs={12} md={4}>
                <Center>
                  <StaticImage
                    src="../../images/partners/urban-league.jpg"
                    alt="elb logo"
                    placeholder="blurred"
                  />
                </Center>
              </Grid>
              <Grid item xs={12} md={4}>
                <Center>
                  <StaticImage
                    src="../../images/partners/united-way.jpg"
                    alt="elb logo"
                    placeholder="blurred"
                  />
                </Center>
              </Grid>
            </Grid>
          </Section>
        </AnimateOnScroll>
      </Segment>
      <Segment variant="primary">
        <AnimateOnScroll animateIn="fadeIn" animateOut="fadeIn">
          <Container>
            <Grid container mb={4}>
              <Grid item xs={12}>
                <Typography variant="h4" color="common.white" align="center">
                  Corporate Donors
                </Typography>
                <FancyHr alt />
                <Typography color="common.white" align="center">
                  Set up a monthly plan in order to become one of our Platinum
                  Corporate Donors
                </Typography>
              </Grid>
            </Grid>
          </Container>
          <Section>
            <Grid container spacing={2}>
              <Grid item xs={12} md={4}>
                <Center>
                  <StaticImage
                    src="../../images/partners/joyner-hand.jpg"
                    alt="elb logo"
                    placeholder="blurred"
                  />
                </Center>
              </Grid>
              <Grid item xs={12} md={4}>
                <Center>
                  <StaticImage
                    src="../../images/partners/touchless.jpg"
                    alt="elb logo"
                    placeholder="blurred"
                  />
                </Center>
              </Grid>
              <Grid item xs={12} md={4}>
                <Center>
                  <StaticImage
                    src="../../images/partners/dubose.jpg"
                    alt="elb logo"
                    placeholder="blurred"
                  />
                </Center>
              </Grid>
            </Grid>
          </Section>
        </AnimateOnScroll>
      </Segment>
    </>
  )
}
export default Partners
